<template>
    <div v-if="showPopup" class="cookies-popup">
      <p class="text-white">ipNX uses cookies to provide necessary site functionality and ensure you get the best browsing experience. By using our website, you agree to our
        <router-link to="/cookie-policy" class="text-white hover:underline">cookie policy</router-link>.
      </p>
      <button @click="acceptCookies" class="bg-white px-2 py-2 rounded-[25px]">Accept cookies</button>
    </div>
  </template>
  
  <script>
  import VueCookies from 'vue-cookies';
  export default {
    data() {
      return {
        showPopup: false,
      };
    },
        mounted() {
            const cookiesAccepted = VueCookies.get('cookiesAccepted');
                if (!cookiesAccepted) {
                this.showPopup = true;
            }
        },

        methods: {
            acceptCookies() {
                VueCookies.set('cookiesAccepted', true, 365);
                this.showPopup = false;
            },
        },
  };
  </script>
  
  <style scoped>
  .cookies-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background: #E91C23;
    text-align: center;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }
  </style>
  