<template>
    <div class="my-auto">
        <div class="flex flex-wrap mt-4">
        <div class="w-full xl:w-full mb-12 xl:mb-8 px-4">
            <card-table />
        </div>
        </div>
    </div>
</template>
<script lang="js">
    import CardTable from "@/components/Cards/CardTable.vue";
    export default {
        name: "dashboard-page",
        components: {
        CardTable,
        },
    };
</script>
  