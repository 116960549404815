import axios from "axios";
import store from "./store";
import router from "./router/index.js";
const apiKey = process.env.VUE_APP_API_URL;

const axiosClient = axios.create({
  baseURL: apiKey
})

axiosClient.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${store.state.user.token}`
   return config;
})

axiosClient.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    store.commit('setToken', null)
    router.push({name: 'login'})
  }
  throw error;
})

export default axiosClient;