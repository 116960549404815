<template>
    <div>
      <h1 class="text-[26px] mb-4 font-bold text-center">Apply for {{ jobDetails.jposition }}</h1>
      <!-- <button @click="submitJob(jobDetails.id)" class="bg-pigmentred w-full text-white text-[14px] py-3 rounded-[6px]">Use previous applications</button> -->
      <form @submit.prevent="nextStep" class="mt-8">
        <p class="text-black text-[14px] mb-5">All fields marked with <span class="text-pigmentred">*</span> must be completed
          before you can move to the next section.
        </p>
        <!-- <div class="form-group mb-5">
          <label class="block mb-2 text-[14px] text-gray-700 font-medium">First Name</label>
          <input v-model="localFirstName" type="text" class="border-gray-300 rounded-[6px] text-gray-500 text-[14px] w-full" placeholder="Enter your first name" required>
        </div>
        
        <div class="form-group mb-5">
          <label class="block mb-2 text-[14px] text-gray-700 font-medium">Middle Name</label>
          <input v-model="localMiddleName" type="text" class="border-gray-300 rounded-[6px] text-gray-500 text-[14px] w-full" placeholder="Enter your middle name" required>
        </div>

        <div class="form-group mb-5">
          <label class="block mb-2 text-[14px] text-gray-700 font-medium">Last Name</label>
          <input v-model="localLastName" type="text" class="border-gray-300 rounded-[6px] text-gray-500 text-[14px] w-full" placeholder="Enter your last name"  required>
        </div> -->

        <div class="form-group mb-5">
            <label class="block mb-2 text-[14px] text-gray-700 font-medium">Gender<span class="text-pigmentred">*</span></label>
            <select v-model="localGender" name="gender" class="border border-gray-300 rounded-[6px] text-gray-500 text-[14px] w-full" placeholder="Choose your gender" required>
              <option value="">Choose your Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Others">Others</option>
            </select>
        </div>

        <div class="form-group mb-5">
            <label class="block mb-2 text-[14px] text-gray-700 font-medium">Marital Status<span class="text-pigmentred">*</span></label>
            <select v-model="localMaritalStatus" name="maritalStatus" class="border border-gray-300 rounded-[6px] text-gray-500 text-[14px] w-full"  required>
              <option value="">Marital Status</option>
              <option value="Single">Single</option>
              <option value="Married">Married</option>
              <option value="Divorced">Divorced</option>
              <option value="Separated">Separated</option>
              <option value="Widowed">Widowed</option>
            </select>
        </div>


        <!-- <div class="form-group mb-5">
          <label class="block mb-2 text-[14px] text-gray-700 font-medium">Phone Number (11 digits)<span class="text-pigmentred">*</span></label>
          <input v-model="localPhoneNumber" type="text" class="border-gray-300 rounded-[6px] text-gray-500 text-[14px] w-full" placeholder="Phone Number" pattern="\d{11}" required>
        </div> -->
        <div class="form-group mb-5">
          <label class="block mb-2 text-[14px] text-gray-700 font-medium">Phone Number<span class="text-pigmentred">*</span></label>
          <div class="input relative">
            <vue-tel-input v-model="phone" class="border-gray-300 rounded-[6px] text-gray-500 text-[14px] w-full" required></vue-tel-input>
          </div>
        </div>

        <div class="form-group mb-5">
          <label class="block mb-2 text-[14px] text-gray-700 font-medium">Residential Address<span class="text-pigmentred">*</span></label>
          <input v-model="localResidentialAddress" type="text" class="border-gray-300 rounded-[6px] text-gray-500 text-[14px] w-full" placeholder="Residential Address" required>
        </div>

        <!-- <div class="form-group mb-5">
          <label class="block mb-2 text-[14px] text-gray-700 font-medium">Residential State</label>
          <input v-model="localResidentialState" type="text" class="border-gray-300 rounded-[6px] text-gray-500 text-[14px] w-full" placeholder="Residential State" required>
        </div> -->
        <div class="form-group mb-5">
          <label class="block mb-2 text-[14px] text-gray-700 font-medium">Country<span class="text-pigmentred">*</span></label>
          <select v-model="localCountry" class="border-gray-300 rounded-[6px] text-gray-500 text-[14px] w-full" required>
            <option value="">Select Country</option>
            <option v-for="country in countries" :key="country.code" >
              {{ country.name }}
            </option>
          </select>
        </div>

        <div v-if="localCountry === 'Nigeria'" class="form-group mb-5">
          <div class="form-group mb-5">
            <label class="block mb-2 text-[14px] text-gray-700 font-medium">Residential State<span class="text-pigmentred">*</span></label>
            <select v-model="localResidentialState" class="border-gray-300 rounded-[6px] text-gray-500 text-[14px] w-full" required>
              <option value="">Select State</option>
              <option v-for="stateData in statesAndLocalGovernments" :key="stateData.alias" >
                {{ stateData.state }}
              </option>
            </select>
          </div>

          <div class="form-group mb-5">
            <label class="block mb-2 text-[14px] text-gray-700 font-medium">State of Origin<span class="text-pigmentred">*</span></label>
            <select v-model="localStateOfOrigin" class="border-gray-300 rounded-[6px] text-gray-500 text-[14px] w-full" required>
              <option value="">Select State</option>
              <option v-for="stateData in statesAndLocalGovernments" :key="stateData.alias" :value="stateData.alias">
                {{ stateData.state }}
              </option>
            </select>
          </div>

          <div class="form-group mb-5">
            <label class="block mb-2 text-[14px] text-gray-700 font-medium">Local Government<span class="text-pigmentred">*</span></label>
            <select v-model="localLocalGovernment" class="border-gray-300 rounded-[6px] text-gray-500 text-[14px] w-full" required>
              <option value="">Select Local Government</option>
              <option v-for="lg in filteredLocalGovernments" :key="lg">{{ lg }}</option>
            </select>
          </div>
        </div>

        <div class="form-group mb-5">
          <label class="block mb-2 text-[14px] text-gray-700 font-medium">Date of Birth<span class="text-pigmentred">*</span></label>
          <input v-model="localDateOfBirth" type="date" class="border-gray-300 rounded-[6px] text-gray-500 text-[14px] w-full" placeholder="Date of Birth" required>
        </div>
        <div class="flex justify-center">
          <button type="submit" class="bg-pigmentred w-1/2 text-white text-[14px] py-3 rounded-[10px]">Next</button>
        </div>
      </form>
    </div>
  </template>
  
<script>
  import axios from 'axios';
  import store from '../store'
  export default {
    props: ['jobDetails', 'formData'],
    name: 'StepOne',
    data() {
      return {
        // localFirstName: this.formData.firstName,
        // localLastName: this.formData.lastName,
        // localMiddleName: this.formData.middleName,
        localGender: this.formData.gender,
        localMaritalStatus: this.formData.marital_status,
        localResidentialAddress: this.formData.residential_address,
        localResidentialState: this.formData.residential_state,
        localStateOfOrigin: this.formData.state_of_origin,
        localLocalGovernment: this.formData.local_government,
        localDateOfBirth: this.formData.date_of_birth,
        phone: this.formData.phoneNumber,
        localCountry: this.formData.country,
        statesAndLocalGovernments: [],
        selectedState: "",
        selectedLocalGovernment: "",
        countries: [],
      };
    },
    methods: {
        nextStep() {
          this.$emit('step1-complete', {
            // firstName: this.localFirstName,
            // lastName: this.localLastName,
            // middleName: this.localMiddleName,
            gender: this.localGender,
            marital_status: this.localMaritalStatus,
            residential_address: this.localResidentialAddress,
            residential_state: this.localResidentialState,
            state_of_origin: this.localStateOfOrigin,
            local_government: this.localLocalGovernment,
            date_of_birth: this.localDateOfBirth,
            phoneNumber: this.phone,
            country: this.localCountry,
          });
        },

      submitJob(id) {
        console.log('Button clicked:', id)
        const allData = {
          id : id
        };
        axios.post(`${process.env.VUE_APP_API_URL}auto-apply`, allData,
        {headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${store.state.user.token}`
            }
          })
          .then(response => {
            this.$swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Application submitted successfully',
                showConfirmButton: false,
                timer: 1500
              });
            console.log('Application submitted successfully', response.data);
            return this.$router.push("/")
          })
          .catch(error => {
            this.$swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'You have no previous application with us',
              showConfirmButton: false,
              timer: 1500
            });
            console.error('Error submitting application', error);
          });
        },
    },

    computed: {
      filteredLocalGovernments() {
        const selectedStateData = this.statesAndLocalGovernments.find(
          entry => entry.alias === this.localStateOfOrigin
        );
        return selectedStateData ? selectedStateData.lgas : [];
      }
    },

    mounted() {
      axios.get("/StateAndLgs.json").then(response => {
        this.statesAndLocalGovernments = response.data;
      });

      axios.get("/Countries.json").then(response => {
        this.countries = response.data;
      });

    }
  };
</script>
  