<!-- <template>
  <router-view/>
</template> -->

<template>
  <div id="app">
    <keep-alive>
      <router-view />
    </keep-alive>
    <CookiesPopup />
  </div>
</template>


<script>
  import CookiesPopup from './pages/CookiesPopup.vue';
  export default {
    name: 'App',
    created() {
      const targetRoute = '';
      if (targetRoute) {
        // Redirect the user to the stored target route.
        this.$router.push(targetRoute);
      }
    },

    components: {
        CookiesPopup,
    },
  }
</script>

