export function setUser(state, user) {
    state.user.data = user;
  }
  
export function setToken(state, token) {
  state.user.token = token;
  if (token) {
    localStorage.setItem('TOKEN', token);
  } else {
    localStorage.removeItem('TOKEN')
  }
}

export function setTargetRoute(state, route) {
  state.targetRoute = route;
}

export function clearTargetRoute(state) {
  state.targetRoute = null;
}


