import { createRouter, createWebHistory } from 'vue-router'
import store from "../store"
import MainLayout from '../components/layouts/MainLayout.vue'
import StepOne from '../pages/StepOne.vue';
import Profile from "../components/layouts/Admin.vue"
import Dashboard from "../pages/Profile/Dashboard.vue"

const routes = [

  {
    path: '/',
    name: 'Welcome',
    component: () => import('../pages/HomePage.vue')
  },

  {
    path: '/cookie-policy',
    name: 'Cookie',
    component: () => import('../pages/CookiePolicy.vue')
  },

  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../pages/PrivacyPolicy.vue')
  },

  {
    path: '/joblistings',
    name: 'mainLayout',
    component: MainLayout,
    children: [
      {
        path: '/joblistings',
        name: 'JobListing',
        component: () => import('../pages/JobListing.vue')
      },
      {
        path: '/apply/:id',
        name: 'ApplyJobs',
        component: () => import('../pages/ApplyJobs.vue')
      },
      {
        path: '/login',
        name: 'LoginPage',
        component: () => import('../pages/auth/LoginPage.vue')
      },
      {
        path: '/email-verify',
        name: 'EmailVerify',
        component: () => import('../pages/auth/EmailVerify.vue')
      },
      {
        path: '/forgot-password',
        name: 'ForgotPasswordPage',
        component: () => import('../pages/auth/ForgotPassword.vue')
      },
      {
        path: '/reset-password',
        name: 'ResetPasswordPage',
        component: () => import('../pages/auth/ResetPassword.vue')
      },

      {
        path: '/signup',
        name: 'SignUpPage',
        component: () => import('../pages/auth/SignUp.vue')
      },
      
    ]
  },

  {
    path: "/profile/dashboard",
    name: "profile-board",
    component: Profile,
      children: [
        {
          path: "/profile/dashboard",
          meta: {
            requiresAuth: true
          },
          component: Dashboard,
        },
        {
          path: "/profile/picture",
          meta: {
            requiresAuth: true
          },
          component: () => import('../pages/Profile/ProfilePicture.vue')
        },
        {
          path: "/edit-profile",
          meta: {
            requiresAuth: true
          },
          component: () => import('../pages/EditProfile.vue')
        },
      ],
    },

  {
    path: '/submit-application/:id',
    name: 'SubmitApplication',
    meta: {
      requiresAuth: true
    },
    component: () => import('../pages/SubmitApplication.vue')
  },

  {
    path: '/SubmitApplication/:id',
    name: 'UpdateApplication',
    meta: {
      requiresAuth: true
    },
    component: () => import('../pages/UpdateApplication.vue')
  },

  {
    path: '/change-password',
    name: 'ChangePassword',
    meta: {
      requiresAuth: true
    },
    component: () => import('../pages/ChangePassword.vue')
  },

  {
    path: '/step-one',
    component: StepOne,
    props: true, 
  },

  
  { path: "/:pathMatch(.*)*", redirect: "/" },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.state.user.token) {
    next({name:'LoginPage'})
  } else if (to.meta.requiresGuest && store.state.user.token) {
    return next({name: 'JobListing'})
  } else {
    return next();
  }
})

export default router
