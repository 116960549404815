<template>
    <div>
      <a
        class="text-blueGray-500 block"
        href="#"
        ref="btnDropdownRef"
        v-on:click="toggleDropdown($event)"
      >
        <div class="items-center flex">
          <span
            class="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full"
          >
            <!-- <img
              alt="..."
              class="w-full rounded-full align-middle border-none shadow-lg"
              :src="image"
            /> -->
            <img
              alt="My picture"
              class="w-full rounded-full align-middle border-none shadow-lg"
              :src="image"
            />
          </span>
        </div>
      </a>
      <div
        ref="popoverDropdownRef"
        class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        v-bind:class="{
          hidden: !dropdownPopoverShow,
          block: dropdownPopoverShow,
        }"
      >
        <router-link
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          to="/profile/dashboard"
        >
          Dashboard
        </router-link>
        <!-- <a
          href="javascript:void(0);"
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Settings
        </a> -->
        <router-link
            to="/change-password"
            
            >
            <button class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700">
                  Change Password
            </button>
        </router-link>
        <div class="h-0 my-2 border border-solid border-blueGray-100" />
        <button
          @click="logout"
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Logout
      </button>
      </div>
    </div>
</template>
  
<script>
  import store from "@/store/index.js";
  import { createPopper } from "@popperjs/core";
  export default {
    data() {
      return {
        dropdownPopoverShow: false,
        image: '',
      };
    },

    // computed: {
    //   imageAvailable() {
    //     console.log('No image')
    //     //return !!this.image; // Check if image path is available
    //     return this.image && this.image !== '/images/profile.jpeg';
    //   }
    // },

    // computed: {
    //   defaultProfileImage() {
    //     return '/images/profile.jpeg';
    //   },
    // },

    methods: {
      toggleDropdown: function (event) {
        event.preventDefault();
        if (this.dropdownPopoverShow) {
          this.dropdownPopoverShow = false;
        } else {
          this.dropdownPopoverShow = true;
          createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
            placement: "bottom-start",
          });
        }
      },
     async logout() {
        store.dispatch('logout')
          .then(() => {
            return this.$router.push("/")
          })
      },
      async fetchProfilePicture() {
        try {
          const response = await fetch(`${process.env.VUE_APP_API_URL}getProfilePicture`, {
            headers: {
              Authorization: `Bearer ${store.state.user.token}`,
            },
          });
          const blob = await response.blob();
          const reader = new FileReader();
          reader.onload = () => {
            this.image = reader.result; 
          };
          reader.readAsDataURL(blob);
          // const data = await response.json();
          // if (data && data.picture_path) {
          //   this.image = data.picture_path; 
          // }
        } catch (error) {
          console.error(error);
        }
      },
    },
    
    mounted() {
      this.fetchProfilePicture();
    },
  };
</script>