<template>
    <div class="relative bg-Gray md:pt-32 pb-8 pt-12 mb-32">
      <div class="px-4 md:px-10 mx-auto w-full">

      </div>
    </div>
  </template>
  
<script>
  
  export default {
    components: {
      
    },
  };
  
</script>
  