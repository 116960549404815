<template>
    <div
      class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
    >
      <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3 class="font-semibold text-base text-blueGray-700">
              Track Your Application Progress
            </h3>
          </div>
          <!-- <div
            class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
          >
            <button
              class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
            >
              View all Jobs
            </button>
          </div> -->
        </div>
      </div>
      <div class="block w-full overflow-x-auto">
        <!-- Jobs table -->
        <table v-if="items.length > 0" class="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Job Title
              </th>
              <!-- <th
                class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Department
              </th> -->
              <th
                class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Status
              </th>
              <th
                class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Applied Date
              </th>
              <!-- <th
                class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                View Job
              </th> -->
            </tr>
          </thead>
          <tbody v-for="(item, index) in limitedErf" :key="index">
            <tr>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ item.jposition }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ item.status }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ dayjs(item.created_at).format('YYYY-MM-DD') }}
              </td>
              <!-- <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <button
                  class="flex flex-wrap bg-white text-white active:bg-indigo-600 text-xs font-bold px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  @click="showForm(item)"
                >
                  <img src="/images/eye.svg" alt="eye" />
                </button>
              </td> -->
            </tr>
          </tbody>
        </table>
        <div v-else class="text-center py-4">
          <h3>You have not submitted any application yet.</h3>
        </div>
      </div>
    </div>
  </template>
  
<script>
  import axios from 'axios';
  import store from '../../store'
  import dayjs from 'dayjs';

  export default {
    components: {

    },

    data() {
      return {
        items: [],
        limitedErf: [],
        dayjs,
        erfPopup:false
      };
    },

    methods: {
        erfShow(){
          this.erfPopup = false
        },

        showForm(row) {
          this.selectedRow = row;
          this.erfPopup = true;
        },
    },
    

      created() {
      axios.get(`${process.env.VUE_APP_API_URL}applicationStatus`,
      {headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${store.state.user.token}`,
                    'Access-Control-Allow-Origin': '*',
                }
                })
        .then(response => {
          this.items = response.data; 
          this.limitedErf = this.items.slice(0, 5);
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
        });
      }
  };
</script>







  