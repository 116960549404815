import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import './assets/css/style.scss'
import "@fortawesome/fontawesome-free/css/all.min.css";
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

createApp(App).use(router).use(store).use(VueSweetalert2).use(VueTelInput).mount('#app')
