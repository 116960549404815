<template>
    <!-- Navbar -->
    <nav
      class="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4"
    >
      <div
        class="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4"
      >
        <!-- Brand -->
        <a
          class="text-pigmentred text-sm hidden lg:inline-block font-bold"
          href="javascript:void(0)"
        >
         <span style="font-size: 25px;">Welcome {{ user }}</span>
        </a>
        <!-- Form -->
        <form
          class="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3"
        >
        </form>
        <!-- User -->
        <ul class="flex-col md:flex-row list-none items-center hidden md:flex">
          <user-dropdown />
        </ul>
      </div>
    </nav>
    <!-- End Navbar -->
  </template>
  
<script>
  import UserDropdown from "@/components/UserDropdown.vue";
  import axios from "axios";
  import store from '../../store'
  
  export default {
    components: {
      UserDropdown,
    },
    data() {
      return {
        user: null,
      };
    },
    created() {
      this.fetchUserData();
    },
    methods: {
    async fetchUserData() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}welcome`,{
        headers: {
          'Authorization': `Bearer ${store.state.user.token}`,
          'Access-Control-Allow-Origin': '*',
        },
      });
        this.user = response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
    
  };
</script>
  